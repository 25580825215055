import { graphql } from 'gatsby';
import React from 'react';

import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Tags from '../components/tags';

const TagSite = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const posts = data.allMarkdownRemark.edges;
  return (
    <Layout>
      <Header metadata={data.site.siteMetadata} />
      <SEO title={tag} />
      <Tags posts={posts} tag={tag} />
    </Layout>
  );
};

export default TagSite;

export const pageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        github
        linkedin
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: { fields: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            tags
          }
          excerpt
          frontmatter {
            title
            date(formatString: "DD-M-YYYY")
            description
            tags
          }
        }
      }
    }
  }
`;
